import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/Layout';

import Hero from '../components/Home/Hero';
import { Couriers } from '../components/Home/Couriers';
import { USP } from '../components/Home/USP';
import CourierSteps from '../components/Home/CourierSteps';
import CourierRequirements from '../components/Home/CourierRequirements';
import ContactForm from '../components/Home/ContactForm';

export default function IndexPage({ data }) {
	return (
		<Layout footerImg={data.logo_footer}>
			<Hero images={data} />
			<Couriers images={data} />
			<USP images={data} />
			<CourierSteps images={data} />
			<CourierRequirements images={data} />
			<ContactForm images={data} />
		</Layout>
	);
}

export const query = graphql`
	{
		logo_footer: file(relativePath: { eq: "logo_footer.png" }) {
			childImageSharp {
				fixed(width: 92, quality: 100) {
					...GatsbyImageSharpFixed_withWebp_tracedSVG
				}
			}
		}
		david_hero: file(relativePath: { eq: "david_hero.png" }) {
			childImageSharp {
				fluid(maxWidth: 500, quality: 100) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
		app: file(relativePath: { eq: "app.png" }) {
			childImageSharp {
				fluid(maxWidth: 700, quality: 100) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
		pic_david_form: file(relativePath: { eq: "pic_david_form.png" }) {
			childImageSharp {
				fluid(maxWidth: 800, quality: 100) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
		pic_futar_1: file(relativePath: { eq: "pic_futar_1.png" }) {
			childImageSharp {
				fixed(width: 150, quality: 100) {
					...GatsbyImageSharpFixed_withWebp_tracedSVG
				}
			}
		}
		pic_futar_2: file(relativePath: { eq: "pic_futar_2.png" }) {
			childImageSharp {
				fixed(width: 150, quality: 100) {
					...GatsbyImageSharpFixed_withWebp_tracedSVG
				}
			}
		}
		illu_register: file(relativePath: { eq: "illu_register.png" }) {
			childImageSharp {
				fluid(maxWidth: 230, quality: 100) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
		illu_worktime: file(relativePath: { eq: "illu_worktime.png" }) {
			childImageSharp {
				fluid(maxWidth: 230, quality: 100) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
		illu_payday: file(relativePath: { eq: "illu_payday.png" }) {
			childImageSharp {
				fluid(maxWidth: 230, quality: 100) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
		illu_vehicle: file(relativePath: { eq: "illu_vehicle.png" }) {
			childImageSharp {
				fluid(maxWidth: 180, quality: 100) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
		illu_phone: file(relativePath: { eq: "illu_phone.png" }) {
			childImageSharp {
				fluid(maxWidth: 180, quality: 100) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
		illu_contract: file(relativePath: { eq: "illu_contract.png" }) {
			childImageSharp {
				fluid(maxWidth: 180, quality: 100) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
		illu_deliver: file(relativePath: { eq: "illu_deliver.png" }) {
			childImageSharp {
				fluid(maxWidth: 180, quality: 100) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
		ic_nextstep: file(relativePath: { eq: "ic_nextstep.png" }) {
			childImageSharp {
				fluid(maxWidth: 101, quality: 100) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
	}
`;
