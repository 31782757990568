import React from 'react';
import Img from 'gatsby-image';
import { CourierForm } from '../shared/CourierForm';

import './ContactForm.scss';

const ContactForm = ({ images }) => {
	const { pic_david_form } = images;

	return (
		<section id="contact-form" className="pb-0 bg-white">
			<div className="container">
				<div className="row pt-sm-5">
					<div className="col-12 col-sm-6 order-sm-1">
						<h2 className="text-center text-sm-left my-4 mt-sm-0 ml-md-0">
							Vidéken élsz, és szeretnél ViddL futár lenni?
						</h2>
						<CourierForm />
					</div>
					<div className="col-12 col-sm-6 order-sm-0 d-none d-sm-flex align-items-sm-end justify-content-sm-center">
						<Img
							fluid={pic_david_form.childImageSharp.fluid}
							alt="Csatlakozz a ViddL futár közösséghez!"
							style={{
								width: '70%',
								maxWidth: 800,
							}}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ContactForm;
