import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import './CourierSteps.scss';

const CourierSteps = ({ images }) => {
	const { illu_register, illu_worktime, illu_payday, ic_nextstep } = images;

	const content = [
		{
			title: 'Jelentkezz, és kössünk szerződést',
			text: 'Jelentkezésed után felhívunk, és behívunk egy személyes találkozóra. Ha tetszik amit hallasz, akár azonnal meg is köthetjük a szerződést!',
			img: illu_register,
			id: 'register',
			arrows: false,
		},
		{
			title: 'Dolgozz amikor és ahogyan szeretnél!',
			text: 'Lépj be az applikációdba, és foglalj magadnak munkaidőt saját döntéseid szerint.',
			img: illu_worktime,
			id: 'worktime',
			arrows: true,
		},
		{
			title: 'Élvezd a kétheti fizetést!',
			text: 'Dolgozz amennyit akarsz, és mi kéthetente fizetünk érte. Ilyen egyszerű az egész!',
			img: illu_payday,
			id: 'payday',
			arrows: false,
		},
	];

	const renderArrow = (className) => (
		<Img
			fluid={ic_nextstep.childImageSharp.fluid}
			className={className}
			style={{
				position: 'absolute',
				maxWidth: 101,
				width: 101,
				top: '7rem',
			}}
		/>
	);

	return (
		<section id="courier-steps" className="pb-0">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<h2 className="text-center mb-5">
							Így válhatsz ViddL futárrá akár már holnap
						</h2>
					</div>
					{content.map(({ title, text, img, arrows, id }) => (
						<div className="col-12 col-md pb-sm-5 mb-sm-2" id={id} key={title}>
							{arrows && renderArrow('arrow-left')}
							<Img
								fluid={img.childImageSharp.fluid}
								style={{
									maxWidth: 230,
									width: '70%',
									marginLeft: 'auto',
									marginRight: 'auto',
								}}
								alt={title}
								className="mt-5 mb-3 mt-sm-3"
							/>
							<h4 className="text-center my-4">{title}</h4>
							<p className="text-center text-secondary">{text}</p>
							{arrows && renderArrow('arrow-right')}
						</div>
					))}
					<div className="col-12 text-center mt-5 pt-4">
						<Link
							to="/courier"
							className="btn btn-primary btn-lg"
							aria-label="Jelentkezek futárnak"
						>
							Jelentkezek futárnak
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CourierSteps;
